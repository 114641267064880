import ContactAddressProps from "@prop-types/ContactAddressProps";
import { ContactAddressBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import React from "react";
import { Media } from "react-bootstrap";

const ContactAddress = ({
  visibleOnPage,
  address1,
  address2,
  postalCode,
  city,
  country,
  title,
  subtitle
}) => {
  if (!visibleOnPage) {
    return null;
  }

  const addressUri = [address1, address2, postalCode, city, country]
    .filter(Boolean)
    .join(" ");

  const googleMapUrl =
    "https://www.google.com/maps/search/?api=1&query=" + encodeURI(addressUri);

  return (
    <Media className={getComponentClassName(ContactAddressBS)}>
      {/* <div>
        <a
          href={googleMapUrl}
          target="_blank"
          rel="noopener noreferrer"
          title={title}
        >
          <FontAwesomeIcon icon="map-marker-alt" size="3x" />
        </a>
      </div> */}
      <div>
        <Media.Body className="text-center">
          <p className="h6">
            <a href={googleMapUrl} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </p>
          <small>{subtitle}</small>
          <div className="address">
            <span className="address1">{address1}</span>
            <span className="address2">{address2}</span>

            <div>
              <span className="postal-code d-inline-block mr-2">
                {postalCode}
              </span>
              <span className="city d-inline-block">{city}</span>
            </div>
            <span className="country">{country}</span>
          </div>
        </Media.Body>
      </div>
    </Media>
  );
};

export default ContactAddress;

ContactAddress.propTypes = {
  ...ContactAddressProps
};
