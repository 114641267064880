import { PAGE_KEY_PRODUCT, PRODUCT_SELECTOR_TYPE_RELATED } from "@constants";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/ProductTemplate";

const ProductTemplate = props => {
  const defaultTemplate = [
    {
      component: "Product/SiteRouteProduct"
    },
    {
      component: "Product/SiteRouteSlider",
      props: {
        selectorType: PRODUCT_SELECTOR_TYPE_RELATED,
        className: "px-3 pt-5"
      }
    },
    {
      component: "Product/MediaEmbeder"
    },
    // {
    //   component: "Product/CardDeckImageFootnote"
    // },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

ProductTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_PRODUCT,
  templateKey: TEMPLATE_KEY
};

export default ProductTemplate;
