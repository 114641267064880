import { PAGE_KEY_BUYING_GUIDE } from "@constants";
import { LayoutFullWidthBS } from "@style-variables";
import { scrollIntoView } from "@utils/functions";
import { findDOMNode } from "react-dom";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "sites/BuyingGuideTemplate";

const BuyingGuideTemplate = props => {
  const jumpRefs = {};

  /**
   * @description This is a React like forwardRef constructor, without React and its `strict` warnings
   * @param {number} key A key associated with the referecen
   * @returns {function} Returns the ref factory
   */
  const forwardRef = key => ref => {
    jumpRefs[key] = ref;
  };

  const onJump = (e, i) => {
    if (jumpRefs[i]) {
      scrollIntoView(findDOMNode(jumpRefs[i]));
    }
  };

  const defaultTemplate = [
    {
      component: "core/TitledParagraph",
      props: { ...props.buyGuide.lead, titleAs: "h1" }
    },
    {
      component: "CardDeck/Overlay/Row",
      props: {
        className: "cursor-pointer",
        title: null,
        hasButton: false,
        items: props.buyGuideAnchors.items.map((item, i) => ({
          ...item,
          // i-index refers the childRef forward-created (see `forwardRef`)
          onClick: e => onJump(e, i)
        }))
      }
    },
    {
      component: "core/TitledParagraph",
      props: props.buyGuide.servingTemp
    },
    {
      component: "core/Picture",
      props: props.buyGuide.servingTempImg.src
        ? {
            className: "w-100",
            ...props.buyGuide.servingTempImg
          }
        : null
    },
    {
      component: "Newspaper/Anchors",
      props: {
        ...props.buyGuide.goldenRules,
        titleAs: "h2",
        childRef: forwardRef(0)
      } // 0-index as the preferred/targeted element within props.buyGuideAnchors.items
    },
    {
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.featuredProductCategory.lead,
        titleAs: "h2",
        childRef: forwardRef(1) // 1-index as the preferred/targeted element within props.buyGuideAnchors.items
      }
    },
    {
      component: "CardDeck/Image/Footnote",
      props: {
        title: "",
        items: props.buyGuide.featuredProductCategory.items
      }
    },
    {
      component: "core/TitledParagraph",
      props: {
        ...props.buyGuide.faq.lead,
        titleAs: "h2",
        childRef: forwardRef(2)
      } // 2-index as the preferred/targeted element within props.buyGuideAnchors.items
    },
    {
      component: "Product/CollapsibleFAQ",
      props: {
        items: props.buyGuide.faq.items,
        className: "ignore-" + LayoutFullWidthBS
      }
    },
    // {
    //   component: "CardDeck/Image/Footnote",
    //   
    //   props: {
    //     ...props.imageFootnoteCardDeck,
    //     centered: true,
    //     asOverlay: true,
    //     titleAs: "h3"
    //   }
    // },
    {
      component: "ProductCategory/Featured",
      props: props.searchBanner
    },
    ...props.widgets
  ].filter(item => Boolean(item.props));

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

BuyingGuideTemplate.mapValueToProps = value => ({
  searchBanner: value.searchBanner,
  buyGuideAnchors: {
    items: value.buyGuideAnchors.items
  },
  buyGuide: {
    lead: value.buyGuide.lead,
    servingTemp: value.buyGuide.servingTemp,
    servingTempImg: value.buyGuide.servingTempImg,
    goldenRules: value.buyGuide.goldenRules,
    faq: { lead: value.buyGuide.faq.lead, items: value.buyGuide.faq.items },
    featuredProductCategory: {
      lead: value.buyGuide.featuredProductCategory.lead,
      items: value.buyGuide.featuredProductCategory.items.map(item => ({
        ...item,
        titleAs: "span",
        children: {
          ...item.children,
          props: { ...item.children.props, titleAs: "h3" }
        }
      }))
    }
  },
  imageFootnoteCardDeck: value.imageFootnoteCardDeck
});

BuyingGuideTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_BUYING_GUIDE,
  templateKey: TEMPLATE_KEY
};

export default BuyingGuideTemplate;
