import { PAGE_KEY_CUSTOMER_SERVICE } from "@constants";
import { LayoutFullWidthBS } from "@style-variables";
import React from "react";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/CustomerServiceTemplate";

const CustomServiceTemplate = props => {
  const defaultTemplate = [
    {
      component: "core/Media",
      props: {
        ...props.customerServiceLead,
        as: "h1",
        preload: true,
        className: LayoutFullWidthBS,
        renderer: ({ renderImageCol, renderBodyCol }) => (
          <div>
            {renderBodyCol("div")}
            {renderImageCol("div")}
          </div>
        )
      }
    },
    {
      component: "Contact/CardDeck",
      props: props.customerServiceContact
    },
    {
      component: "core/TitledParagraph",
      props: props.customerServiceFootnote
    },
    // {
    //   component: "CardDeck/Image/Footnote",
    //   
    //   props: {
    //     ...props.imageFootnoteCardDeck,
    //     centered: true,
    //     asOverlay: true,
    //     titleAs: "h3"
    //   }
    // },
    {
      component: "ProductCategory/Featured",
      props: props.searchBanner
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

CustomServiceTemplate.mapValueToProps = value => ({
  customerServiceLead: value.customerServiceLead,
  customerServiceContact: value.customerServiceContact,
  customerServiceFootnote: value.customerServiceFootnote,
  searchBanner: value.searchBanner,
  imageFootnoteCardDeck: value.imageFootnoteCardDeck
});

CustomServiceTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_CUSTOMER_SERVICE,
  templateKey: TEMPLATE_KEY
};

export default CustomServiceTemplate;
