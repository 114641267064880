import { EVENT_THUMBNAIL_FORCE_UPDATE } from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import MaxSizeProps from "@prop-types/MaxSizeProps";
import { PictureBS } from "@style-variables";
import { getImageMimeType } from "@utils/image";
import PictureHelper from "@utils/picture";
import { bytesToHuman, getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Image as BootstrapImage } from "react-bootstrap";
import LazyAwareComponent from "./LazyAwareComponent";
import { getClassname } from "./Placeholder";

// TODO: blur up effect
// https://css-tricks.com/the-blur-up-technique-for-loading-background-images/
//
// TruthSocial.com uses a canvas with negative z-index and a div with a background-image.
// The canvas shows the low-res/blured image, the div loads on background the real image.
// As the real image loading progresses it overlaps the blured image. Finally the real image
// sits on top the blured image.

/**
 * @description A picture component able to render a Cloudinary|normal multi-source lazy-loading picture/image
 * @export
 * @class Picture
 * @extends {LazyAwareComponent}
 * @see https://www.sitepoint.com/how-to-build-your-own-progressive-image-loader/
 * @see https://web.dev/preload-responsive-images/
 */
class Picture extends LazyAwareComponent {
  static FORCE_THUMBNAIL_CLASS = "force-thumbnail";
  constructor(props) {
    super(props);

    this.handleComponentForceThumbnailUpdate =
      this.handleComponentForceThumbnailUpdate.bind(this);

    this.state = {
      ...this.state,
      loadError: false
    };

    // a picture helper instance
    this.helper = new PictureHelper();

    this.defaultImgRef = React.createRef();
    this.hoverImgRef = React.createRef();
    this.canvasRef = React.createRef();
    this.image = new Image();
    this.thumbnail = null;
  }

  UNSAFE_componentWillMount() {
    this.thumbnail = this.prerenderThumbnail();
  }

  componentDidMount() {
    super.componentDidMount();

    document.addEventListener(
      EVENT_THUMBNAIL_FORCE_UPDATE,
      this.handleComponentForceThumbnailUpdate
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.thumbnail && this.props.thumbnail) {
      this.thumbnail = this.prerenderThumbnail(true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      EVENT_THUMBNAIL_FORCE_UPDATE,
      this.handleComponentForceThumbnailUpdate
    );

    super.componentWillUnmount();
  }

  handleComponentForceThumbnailUpdate() {
    const hasThumbnail = this.hasThumbnail();
    const svgThumbnail =
      hasThumbnail &&
      this.props.thumbnail.startsWith("data:" + getImageMimeType(".svg"));

    if (!hasThumbnail || svgThumbnail) {
      return;
    }

    if (this.canvasRef.current) {
      if (window.__IMAGE_THUMBNAIL__) {
        this.canvasRef.current.parentNode.classList.add(
          Picture.FORCE_THUMBNAIL_CLASS
        );
      } else {
        this.canvasRef.current.parentNode.classList.remove(
          Picture.FORCE_THUMBNAIL_CLASS
        );
      }

      this.canvasRef.current.classList.add("d-none");
    }
  }

  /**
   * @description Draw the thumbnail image
   * @param {Object} props
   * @memberof Picture
   */
  drawThumbnail(props) {
    let width = props.width;
    let height = props.height;
    let aspect = props.aspect;

    this.image.onload = e => {
      const canvas = this.canvasRef.current;
      if (!canvas) {
        return;
      }

      aspect = this.image.naturalHeight / this.image.naturalWidth || aspect;

      width = width || canvas.getBoundingClientRect().width;
      height = height || canvas.getBoundingClientRect().height;

      width = width || Math.floor(height / aspect);
      height = Math.floor(width * aspect) || height;

      canvas.style.width = width + "px";
      canvas.style.height = height + "px";

      // 20 is the default thumbnail size
      canvas.width = width || canvas.width || 20;
      canvas.height = height || canvas.height || 20;

      canvas.setAttribute("data-aspect", aspect);

      canvas.getContext("2d").drawImage(this.image, 0, 0, width, height);

      this.setState({
        canvasWidth: width,
        canvasHeight: height,
        canvasAspect: aspect
      });
    };

    this.image.src = this.props.thumbnail;
  }

  /**
   * @description Prerenders the image thumbnail's canvas
   * @returns {HTMLElement} Returns the thumbnail canvas
   * @memberof Picture
   */
  prerenderThumbnail(force) {
    if (!force) {
      if (this.thumbnail || !this.hasThumbnail()) {
        return this.thumbnail;
      }
    }

    const { defaultImage, sources } = this.getData();
    const { style } = defaultImage;

    let width = 0;
    let height = (style || {}).maxHeight || (style || {}).minHeight || 0;

    if ("string" === typeof height) {
      height = +height.replace(/px/, "");
    }

    if (!height && sources) {
      const { sizes } = sources.find(({ media }) =>
        window.matchMedia(media)
      ) || { sizes: "" };

      const _sizes = sizes.split(",").map(s => +s.replace(/px/, ""));

      width = _sizes[0] || +(defaultImage.width || "").replace(/px/, "") || 0;
      height =
        _sizes[1] ||
        //_sizes[0] ||
        +(defaultImage.height || "").replace(/px/, "") ||
        0;
    }

    // if ((this.props.src || "").indexOf("carousel-3") !== -1) {
    //   console.log({
    //     width,
    //     height,
    //     this: this.props,
    //     thumbnail: this.props.thumbnail
    //   });
    // }

    const thumbnail = (
      <canvas
        ref={this.canvasRef}
        height={height}
        width={width}
        style={{
          width: this.props.aspect > 1.1 ? "auto" : "100%"
        }}
      ></canvas>
    );

    this.drawThumbnail({ aspect: this.props.aspect, width, height });

    return thumbnail;
  }

  /**
   * @description Check wether the picture provides a thumbnail image
   * @returns {Boolean}
   * @memberof Picture
   */
  hasThumbnail() {
    return this.props.thumbnail && "string" === typeof this.props.thumbnail;
  }

  /**
   * @inheritdoc
   * @memberof Picture
   */
  lazyLoad() {
    return super.lazyLoad() && !this.hasThumbnail();
  }

  getData() {
    this.helper.setProps({ ...this.props, lazyLoading: this.shouldLazyLoad() });
    return this.helper.getProps(this.props.src);
  }

  /**
   * @description Render the picture inner image element
   * @param {Object} [props={}] Extra properties to pass to the Image component
   * @returns {JSX}
   * @memberof Picture
   */
  renderDefaultImage(props = {}, wrapped = false) {
    const _style = { ...props.style };

    _style.width = _style.width || this.state.canvasWidth || "auto";
    _style.height = _style.height || this.state.canvasHeight || "auto";

    const hasThumbnail = this.hasThumbnail();
    const svgThumbnail =
      hasThumbnail &&
      props.thumbnail.startsWith("data:" + getImageMimeType(".svg"));

    const setDataContentLength = (el, length) => {
      if (el && length) {
        el.setAttribute("data-image-size", bytesToHuman(length));
      }
    };

    const _image = (
      <BootstrapImage
        {...{
          ...props,
          src: svgThumbnail ? props.thumbnail : props.src,
          thumbnail: hasThumbnail ? undefined : props.thumbnail
        }}
        style={_style}
        onLoad={e => {
          const self = e.currentTarget;

          if (self.complete) {
            PictureHelper.getImageSize(self.src).then(contentLength =>
              setDataContentLength(self.parentNode, contentLength)
            );
          }

          this.setState(
            {
              loadError: false,
              fetched: self.complete
            },
            this.handleComponentForceThumbnailUpdate
          );

          if (this.props.autoHeight) {
            const { width, height } = self.getBoundingClientRect();

            if (height && !self.style.height) {
              self.style.height = height + "px";
            }
            if (width && !self.style.width) {
              self.style.width = width + "px";
            }
          }

          if (typeof this.props.onLoad === "function") {
            this.props.onLoad(e);
          }
        }}
        onError={e => {
          console.error(`Could not load the image ${props.src}`);

          this.setState({ loadError: true, fetched: true });

          if (typeof this.props.onLoad === "function") {
            this.props.onLoad(e);
          }
        }}
      />
    );

    // render the image alone
    if (!hasThumbnail || svgThumbnail) {
      return _image;
    }

    // render the image together with its thumbnail
    return (
      <div
        style={{
          ...props.style,
          height: wrapped ? "100%" : props.style.height
          //minHeight: this.thumbnail.props.height
        }}
        data-thumbnail-size={bytesToHuman(this.props.thumbnail.length)}
        data-image-size={"n/a"}
      >
        {this.thumbnail}
        {_image}
      </div>
    );
  }

  renderVideoSources() {
    const width = Math.max(window.screen.width, window.screen.height); //this.helper.getDefaultImageSize();

    const sources = [
      ["mp4", "hvc1"], // H.265/HEVC-encoded MP4, Safari only
      ["webm", "vp9"], // VP9, a codec supported by Edge, Firefox, and Chrome
      "mp4" // older browser fallback
    ].map((item, i, array) => {
      const [format, codecs] = "string" === typeof item ? [item] : item;

      const url = this.helper.getImageUrl(this.props.src, width, format);
      return (
        <source
          type={["video/" + format, codecs ? "codecs=" + codecs : null]
            .filter(Boolean)
            .join("; ")}
          src={url}
          key={i}
          onError={e => {
            if (i !== array.length - 1) {
              return;
            }

            const url = this.helper.getImageUrl(
              this.props.src,
              this.helper.getDefaultImageSize()
            );
            console.error(`Could not load the image ${url}`);

            this.setState({ loadError: true, fetched: true });

            if (typeof this.props.onLoad === "function") {
              this.props.onLoad(e);
            }
          }}
        />
      );
    });

    sources.push("Your browser does not support HTML5 video tag.");

    return sources;
  }

  /**
   * @description Render the picture as the given component
   * @param {function|Object} AsComponent - An alternative component (eg. <div>)
   * @returns {JSX}
   * @memberof Picture
   */
  renderAs(AsComponent) {
    return (
      <AsComponent
        {...this.helper.stripNonImageAttrs(this.props)}
        fluid={null}
        thumbnail={null}
      />
    );
  }

  renderAsVideo() {
    const className = getComponentClassName(
      PictureBS,
      null,
      this.props.className
    );

    const posterWidth = Math.max(window.screen.width, window.screen.height);
    const poster = this.helper.getImageUrl(this.props.src, posterWidth, "jpg");

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        preload="metadata"
        className={className}
        controls={this.props.controls}
        loop={this.props.loop}
        // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        autoPlay={this.props.autoPlay}
        muted={false}
        width="100%"
        poster={poster}
        onLoadedData={e => {
          //e.currentTarget.play();
        }}
        onLoadedMetadata={e => {
          this.setState({ loadError: false, fetched: true });
          if (typeof this.props.onLoad === "function") {
            this.props.onLoad(e);
          }
        }}
      >
        {this.renderVideoSources()}
      </video>
    );
  }

  /**
   * @description Renders the placeholder image on image error/not loaded
   * @param {String} [className=null] Optional class for the image
   * @returns {FontAwesomeIcon}
   * @memberof Picture
   */
  renderErrorImage(className = null) {
    const icon = this.props.video
      ? this.props.errorVideo
      : this.props.errorImage;

    if (!icon) {
      return null;
    }

    const aspect =
      !this.props.aspect || 1 === this.props.aspect ? 0.618 : this.props.aspect;

    let dataSrc = null;
    if ("function" !== typeof this.props.src) {
      dataSrc = this.helper.getImageUrl(
        this.props.src,
        this.helper.getDefaultImageSize()
      );
    }

    const style = {
      ...(this.props.style || {}),
      ...(this.getResponsiveStyle(aspect, this.props.sizes) || {})
    };

    if (this.props.sizes) {
      const sizes = Object.values(this.props.sizes).filter(Boolean);
      style.maxWidth = style.maxWidth || Math.max(...sizes);
      style.minWidth = style.minWidth || Math.min(...sizes);
      style.maxHeight = Math.round(style.maxWidth * aspect);
      style.minHeight = Math.round(style.minWidth * aspect);
    }

    style.maxHeight =
      style.height ||
      style.maxHeight ||
      (style.maxWidth
        ? style.maxWidth / (this.props.aspect || 1.618)
        : undefined) ||
      undefined;

    const styleKeys = style ? Object.keys(style).length : 0;

    const _className = [
      "mx-auto d-inline-block w-100",
      className,
      styleKeys ? null : "w-75 h-75",
      this.props.src && this.props.src.indexOf("/Error/") !== -1
        ? this.props.errorImageClassName
        : this.props.missingImageClassName
    ]
      .filter(Boolean)
      .join(" ");

    //const color = this.props.src ? "text-light" : "text-black-50";
    // `data-src` attribute is a trace of the real URL that failed loading
    return (
      <FontAwesomeIcon
        style={{ ...style }}
        icon={icon}
        className={_className}
        data-src={dataSrc}
      />
    );
  }

  /**
   * @description Render the placeholder image
   * @returns {JSX}
   * @memberof Picture
   */
  renderPlaceholderImage() {
    return this.renderErrorImage(getClassname());
  }

  /**
   * @description Render the picture as a multi-source image
   * @returns {JSX}
   * @memberof Picture
   */
  renderAsDefault() {
    // note: in case the browser cache is disabled the visible slide image is fetched again
    // a better version would manage its own cached Image collection and assign a pre-loaded Image to the visible slide

    const className = getComponentClassName(
      PictureBS,
      null,
      this.props.className
    );

    // the precomputed/cached picture rendering props
    const data = this.getData();

    if (this.props.placeholder) {
      return this.renderPlaceholderImage();
    }

    if (this.state.loadError || !this.props.src) {
      if (!this.hasThumbnail()) {
        return this.renderErrorImage();
      }
    }

    // probably an inline image (eg. SVG)
    if (typeof this.props.src === "function") {
      return <this.props.src className={className} />;
    }

    if (this.props.video) {
      return this.renderAsVideo();
    }

    const sources = data.sources
      ? data.sources.map((item, index) => (
          <source
            srcSet={item.srcSet}
            sizes={item.sizes}
            media={item.media}
            key={index}
          />
        ))
      : null;

    const defaultPictureProps = this.renderDefaultImage(
      {
        ...data.defaultImage,
        ref: this.defaultImgRef,
        placeholder: null,
        extension: null
      },
      false
    );

    const defaultPicture = (
      <picture className={className}>
        {sources}
        {this.props.ribbons}
        {defaultPictureProps}
      </picture>
    );

    if (
      this.props.autoHover &&
      this.props.hoverImg &&
      this.props.hoverImg.src
    ) {
      // the precomputed/cached picture rendering props
      const hoverProps = this.helper.getProps(this.props.hoverImg.src);

      const hoverSources = hoverProps.sources
        ? hoverProps.sources.map((item, index) => (
            <source
              srcSet={item.srcSet}
              sizes={item.sizes}
              media={item.media}
              key={index}
            />
          ))
        : null;

      const hoverPictureProps = this.renderDefaultImage(
        {
          ...hoverProps.defaultImage,
          ref: this.hoverImgRef,
          className: [
            hoverProps.defaultImage.className,
            "hover-alt-image-hidden",
            "position-absolute"
          ]
            .filter(Boolean)
            .join(" "),
          placeholder: null,
          extension: null
        },
        true
      );

      const hoverPicture = (
        <picture className={className}>
          {hoverSources}
          {this.props.ribbons}
          {hoverPictureProps}
        </picture>
      );

      const onHoverAltImageShow = e => {
        this.defaultImgRef.current.classList.add("hover-alt-image-hidden");
        this.hoverImgRef.current.classList.remove("hover-alt-image-hidden");
      };
      const onHoverAltImageHidden = e => {
        this.defaultImgRef.current.classList.remove("hover-alt-image-hidden");
        this.hoverImgRef.current.classList.add("hover-alt-image-hidden");
      };

      return (
        <div
          style={{ ...data.defaultImage.style, width: "100%" }}
          onMouseEnter={onHoverAltImageShow}
          onMouseOut={onHoverAltImageHidden}
          onBlur={onHoverAltImageHidden}
        >
          {defaultPicture}
          {hoverPicture}
        </div>
      );
    }

    return defaultPicture;
  }

  render() {
    if (this.props.as) {
      return this.renderAs(this.props.as);
    }

    if (!this.state.fetched && this.shouldLazyLoad()) {
      if (typeof this.props.src !== "function") {
        return this.renderAsLazy();
      }
    }

    return this.renderAsDefault();
  }
}

Picture.propTypes = {
  ...ItemsAwareProps,
  ...LazyAwareComponent.propTypes,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  fluid: PropTypes.bool,
  padding: PropTypes.bool,
  imgSize: PropTypes.shape(MaxSizeProps),
  aspect: PropTypes.number,
  cloudinary: PropTypes.shape({
    cloudName: PropTypes.string.isRequired,
    path: PropTypes.string
  }),
  decoding: PropTypes.string,
  onLoad: PropTypes.func,
  version: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  video: PropTypes.bool,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  errorImage: PropTypes.string,
  errorVideo: PropTypes.string,
  errorImageClassName: PropTypes.string,
  placeholder: PropTypes.bool,
  ribbons: PropTypes.arrayOf(PropTypes.element),
  autoHeight: PropTypes.bool,
  autoHover: PropTypes.bool,
  removeBackground: PropTypes.bool
};

Picture.defaultProps = {
  ...LazyAwareComponent.defaultProps,
  fluid: true,
  padding: false,
  decoding: "async",
  errorImage: "image",
  errorVideo: "film",
  errorImageClassName: "text-warning",
  missingImageClassName: "text-light",
  version: false,
  video: false,
  autoPlay: false,
  controls: true,
  loop: true,
  autoHeight: true,
  autoHover: true
};

export default React.memo(Picture);
