/**
* Generated automatically at built-time (2024-12-13T14:49:09.132Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-vinskap",templateKey: "sites/87-1833d0ea-5bcf-40da-b2c0-356fcb66751b"};