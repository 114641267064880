import CollapsibleContainer from "@components-core/CollapsibleContainer";
import PureComponent from "@components-core/PureComponent";
import ScrollToTop from "@components-core/ScrollToTop";
import TextareaCard from "@components-core/TextareaCard";
import { connectHOCs } from "@components-utils";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import OrderCouponWidgetProps from "@prop-types/OrderCouponWidgetProps";
import ShoppingCartCouponProps from "@prop-types/ShoppingCartCouponProps";
import ShoppingCartItemProps from "@prop-types/ShoppingCartItemProps";
import ShoppingCartItemSetupProps from "@prop-types/ShoppingCartItemSetupProps";
import TextareaCardProps from "@prop-types/TextareaCardProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { cartRemoveCoupon } from "@redux-actions/cart-coupon";
import {
  addOrderSubscriber,
  checkoutSetMessage,
  removeOrderSubscription
} from "@redux-actions/checkout";
import { LayoutFullWidthBS, ShoppingCartBS } from "@style-variables";
import { ProductUrlTransformer } from "@transformers/ProductUrl";
import { toHelmetJSX } from "@utils/functions";
import {
  formatCurrency,
  formatNumber,
  getComponentClassName
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Badge, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ShoppingCartCoupon from "./Coupon";
import ShoppingCartItem from "./Item";
import OrderCouponWidget from "./OrderCouponWidget";

// Redux example: https://github.com/reduxjs/redux/tree/master/examples/shopping-cart
class ShoppingCart extends PureComponent {
  constructor(props) {
    super(props);

    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleCouponRemove = this.handleCouponRemove.bind(this);
    this.onPlacingOrder = this.onPlacingOrder.bind(this);
  }

  onPlacingOrder() {
    return {
      comment: this.props.comment,
      amount: this.props.amount, //TODO this should be subject to subtotal component
      items: this.props.items,
      coupons: this.props.coupons
    };
  }

  handleCouponRemove(e, index) {
    this.props.cartRemoveCoupon(index, this.props.siteConfig);
  }

  componentDidMount() {
    if (this.props.isCartEmpty) {
      return;
    }

    // subscribe to order submission event and when it's time provide the bits this component controls
    this.props.addOrderSubscriber(this.onPlacingOrder, 0); // priority 0 since onPlacingOrder has no validation and its info might be used by other event listeners

    setTimeout(ScrollToTop.scrollToTop, 50);
  }

  componentWillUnmount() {
    if (this.props.isCartEmpty) {
      return;
    }
    this.props.removeOrderSubscription(this.onPlacingOrder);
  }

  handleCommentChange(e) {
    this.props.checkoutSetMessage(e.target.value);
  }

  rederCommentCouponInput() {
    if (this.props.isCartEmpty) {
      return null;
    }

    const _formatNumber = value => formatNumber(value, ".", " ");

    const couponWidgetVisible =
      this.props.setup.couponWidget.enabled &&
      (!this.props.setup.couponWidget.maxItems ||
        this.props.coupons.length < this.props.setup.couponWidget.maxItems);

    const md = couponWidgetVisible ? 5 : 9;

    const couponWidget = couponWidgetVisible ? (
      <Col xs="12" md="4" className="pt-3 pt-md-0">
        <OrderCouponWidget {...this.props.setup.couponWidget} />
      </Col>
    ) : null;

    const borderWrapper = {
      as: Container,
      props: {
        className: "wrapper border py-3"
      }
    };

    const i18n = this.props.subtotal.i18n;

    return (
      <Row
        className={getComponentClassName(
          ShoppingCartBS,
          "comment-coupon",
          [this.props.className, "no-gap"].filter(Boolean).join(" ")
        )}
      >
        <Col xs="12" md={md}>
          <CollapsibleContainer
            id="order-comment"
            title={"Vill du skriva ett meddelande?"}
            collapsible
            collapsed={true}
            wrapper={borderWrapper}
          >
            <TextareaCard
              {...this.props.setup.comment}
              value={undefined}
              defaultValue={this.props.comment}
              onBlur={this.handleCommentChange}
              disabled={this.props.checkoutLocked}
              readOnly={this.props.checkoutLocked}
              className="w-100"
            />
          </CollapsibleContainer>
        </Col>
        {couponWidget}
        {/* cart items subtotal */}
        <Col xs="12" md="3" className="pt-3 pt-md-0">
          <div style={{ display: "grid" }} className="justify-content-center">
            <div
              className={getComponentClassName(
                ShoppingCartBS,
                "subtotal-gross",
                this.props.isCalculating ? "calculating" : null
              )}
            >
              <span>{i18n.cartValue}</span>
              <span>
                {formatCurrency(
                  _formatNumber(this.props.amount),
                  this.props.currencyPrefix,
                  this.props.currencySuffix
                )}
              </span>
            </div>
            <div
              className={getComponentClassName(
                ShoppingCartBS,
                "subtotal-discount",
                this.props.isCalculating ? "calculating" : null
              )}
            >
              <span>{i18n.cartDiscount}</span>
              <span>
                {formatCurrency(
                  0,
                  this.props.currencyPrefix,
                  this.props.currencySuffix
                )}
              </span>
            </div>
            <div
              className={getComponentClassName(
                ShoppingCartBS,
                "subtotal-vat-value",
                this.props.isCalculating ? "calculating" : null
              )}
            >
              <span>{i18n.vatValue}</span>
              <span>
                {formatCurrency(
                  _formatNumber(this.props.vat),
                  this.props.currencyPrefix,
                  this.props.currencySuffix
                )}
              </span>
            </div>
            <div
              className={getComponentClassName(
                ShoppingCartBS,
                "subtotal-discounted-value",
                this.props.isCalculating ? "calculating" : null
              )}
            >
              <span>{i18n.cartSubtotal}</span>
              <span>
                {formatCurrency(
                  _formatNumber(this.props.amount),
                  this.props.currencyPrefix,
                  this.props.currencySuffix
                )}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  renderCartItemsTitle() {
    return (
      <Row
        className={getComponentClassName(
          ShoppingCartBS,
          "title",
          [this.props.className, "py-4 my-0 mw-100 w-100", LayoutFullWidthBS]
            .filter(Boolean)
            .join(" ")
        )}
      >
        <Col>
          <h1 className="font-weight-bold">
            {this.props.setup.cart.title ? this.props.setup.cart.title : null}
          </h1>
        </Col>
      </Row>
    );
  }

  /**
   * @description Render the item shown on a empty shopping cart
   * @returns {JSX}
   * @memberof ShoppingCart
   */
  renderEmptyCartItem() {
    if (!this.props.isCartEmpty) {
      return null;
    }

    const i18n = this.props.i18n.components.ShoppingCart.EMPTY_CART;

    const footer = i18n.footer ? (
      <React.Fragment>
        {" "}
        <hr />
        <p className="mb-0">{i18n.footer}</p>
      </React.Fragment>
    ) : null;

    return (
      <Alert variant="warning">
        <Alert.Heading>{i18n.title}</Alert.Heading>
        <p>{i18n.body}</p>
        {footer}
      </Alert>
    );
  }

  /**
   * @description Reder cart items
   * @returns {JSX}
   * @memberof ShoppingCart
   */
  renderCartItems() {
    return this.props.items.map((item, index) => {
      const productUrl = ProductUrlTransformer(
        { searchKey: item.searchKey },
        this.props.pathfinder
      );

      return (
        <Row
          key={index}
          className={
            index < this.props.items.length - 1 || this.props.coupons.length
              ? "border-bottom mb-3"
              : null
          }
        >
          <Col>
            <ShoppingCartItem
              setup={this.props.setup.items}
              {...item}
              href={productUrl}
              disabled={this.props.checkoutLocked}
            />
          </Col>
        </Row>
      );
    });
  }

  /**
   * @description Render cart coupons
   * @returns {JSX}
   * @memberof ShoppingCart
   */
  renderCartCoupons() {
    return this.props.coupons
      .map(item => {
        const invalidCoupon = this.props.invalidCoupons.find(
          invalidCoupon => item.code === invalidCoupon.code
        );
        if (invalidCoupon) {
          return {
            ...item,
            discountValue: null,
            discountError: invalidCoupon
          };
        }

        return item;
      })
      .map((item, index) => (
        <Row key={index} className="text-danger">
          <Col xs="10" sm="11">
            <ShoppingCartCoupon
              setup={this.props.setup.couponsItems}
              {...item}
            />
          </Col>
          <Col xs="2" sm="1">
            <Badge
              className="cursor-pointer"
              variant="danger"
              onClick={e => this.handleCouponRemove(e, index)}
              title={this.props.i18n.components.ShoppingCart.BTN_REMOVE_COUPON}
            >
              X
            </Badge>
          </Col>
        </Row>
      ));
  }

  render() {
    return (
      <>
        {/* TITLE */}
        {this.renderCartItemsTitle()}
        <div>
          <Container
            className={getComponentClassName(
              ShoppingCartBS,
              null,
              [this.props.className, "callout-top"].filter(Boolean).join(" ")
            )}
            fluid
          >
            {/* HELMET */}
            <Helmet prioritizeSeoTags>{toHelmetJSX(this.props.helmet)}</Helmet>

            <Row
              className={getComponentClassName(
                ShoppingCartBS,
                "items",
                this.props.className
              )}
            >
              <Col>
                <div className="d-block">
                  <span className="step-title">
                    {this.props.setup.cart.text}
                  </span>
                  {/* cart itemss & coupons */}
                  <div>
                    {this.renderCartItems()}
                    {this.renderEmptyCartItem()}
                    {this.renderCartCoupons()}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* comment & coupons */}
        <div>{this.rederCommentCouponInput()}</div>
      </>
    );
  }
}

ShoppingCart.propTypes = {
  ...ItemsAwareProps(false, null, PropTypes.shape(ShoppingCartItemProps)),
  ...ItemsAwareProps(
    false,
    "coupons",
    PropTypes.shape(ShoppingCartCouponProps)
  ),
  amount: PropTypes.number.isRequired,
  vat: PropTypes.number.isRequired,
  currencyCode: PropTypes.string,
  currencyPrefix: PropTypes.string,
  currencySuffix: PropTypes.string,
  setup: PropTypes.shape({
    cart: PropTypes.shape(TitleTextProps(true)),
    items: PropTypes.shape(ShoppingCartItemSetupProps),
    comment: PropTypes.shape(TextareaCardProps),
    coupon: PropTypes.shape(OrderCouponWidgetProps)
  }),
  className: PropTypes.string,
  maxOrderCoupons: PropTypes.number
};

ShoppingCart.defaultProps = {
  items: [],
  amount: null,
  vat: null,
  currencyCode: null,
  currencyPrefix: null,
  currencySuffix: null
};

ShoppingCart.mapValueToProps = value => ({
  ...value.checkout.shoppingCart,
  subtotal: value.checkout.subtotal
});

ShoppingCart.mapStateToProps = (state, ownProps) => {
  const gross = state.calculatorResult.gross || {};
  const vat = state.calculatorResult.vat || {};

  return {
    isCartEmpty: !state.cart.items.length,
    amount: gross.cartValue || 0,
    vat: vat.cartValue || 0,
    items: state.cart.items.map((cartItem, index) => ({
      id: cartItem.product.id,
      cartItemIndex: index,
      title: cartItem.product.title,
      subtitle: cartItem.product.subtitle,
      newPrice: cartItem.product.newPrice,
      currencySuffix: cartItem.product.currencySuffix,
      currencyPrefix: cartItem.product.currencyPrefix,
      currencyCode: cartItem.product.currencyCode,
      href: cartItem.product.href,
      quantity: cartItem.quantity,
      amount: cartItem.product.newPrice * cartItem.quantity,
      img: cartItem.product.img,
      searchKey: cartItem.product.searchKey
    })),
    coupons: state.cartCoupons.filter(Boolean) || [],
    invalidCoupons: state.calculatorResult.invalidCoupons || [],
    comment: state.checkout.message || "",
    currencyCode: state.calculatorResult.currencyCode,
    currencyPrefix: state.calculatorResult.currencyPrefix,
    currencySuffix: state.calculatorResult.currencySuffix,
    checkoutLocked:
      (state.placeOrderResult.isFetching ||
        state.placeOrderResult.status ||
        state.checkout.paymentState.initiated) &&
      !(
        state.checkout.paymentState.success ||
        state.checkout.paymentState.failure
      ),
    isCalculating: state.calculatorResult.isFetching
  };
};

ShoppingCart.mapDispatchToProps = {
  cartRemoveCoupon,
  addOrderSubscriber,
  removeOrderSubscription,
  checkoutSetMessage
};

export default connectHOCs(ShoppingCart, {
  withSite: true,
  withConnect: true,
  withGraphQL: true
});
