import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import { PRODUCT_SELECTOR_TYPE_CATEGORY } from "@constants";
import ImageProps from "@prop-types/ImageProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import MaxSizeProps from "@prop-types/MaxSizeProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import {
  checkoutFetchRelatedProduct,
  checkoutFetchRelatedProductFailure,
  checkoutFetchRelatedProductSuccess
} from "@redux-actions/checkout";
import { CheckoutRelatedProductsBS } from "@style-variables";
import { ProductSliderTransformer } from "@transformers/Product";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import ProductSlider from "../Product/Slider";

class CheckoutRelatedProducts extends PureComponent {
  componentDidMount() {
    const mostExpensiveProduct = this.props.mostExpensiveProduct;

    let promise;

    if (mostExpensiveProduct.id) {
      promise = this.props.checkoutFetchRelatedProduct(
        [mostExpensiveProduct.id],
        this.props.siteConfig
      );
    } else {
      promise = Promise.resolve([]);
    }

    promise
      .then(relatedProduct => {
        this.props.checkoutFetchRelatedProductSuccess(relatedProduct);
      })
      .catch(error => {
        this.props.checkoutFetchRelatedProductFailure(error);
      });
  }

  render() {
    const items = this.props.items;

    if (!items.length) {
      return null;
    }

    const { siteId, userId, i18n, pathfinder, graphqlClient } =
      this.props.siteConfig;

    const sliderProps = ProductSliderTransformer(items, {
      siteId,
      userId,
      i18n,
      pathfinder,
      graphqlClient,
      selectorType: PRODUCT_SELECTOR_TYPE_CATEGORY,
      impressionList:
        PRODUCT_SELECTOR_TYPE_CATEGORY +
        "/" +
        this.props.mostExpensiveProduct.searchKey,
      getState: this.props.getState,
      dispatch: this.props.dispatch
    });

    const slider = (
      <ProductSlider
        selectorType={PRODUCT_SELECTOR_TYPE_CATEGORY}
        impressionList={null}
        {...{ ...sliderProps, itemsPerSlide: this.props.itemsPerSlide }}
        title={null}
        imgSize={this.props.imgSize}
        showSubtitle={this.props.showSubtitle}
      />
    );

    if (!this.props.wrapIntoCard) {
      return slider;
    }

    return (
      <div
        className={getComponentClassName(
          CheckoutRelatedProductsBS,
          null,
          ["my-4 mx-3 mx-lg-0 text-center", this.props.className]
            .filter(Boolean)
            .join(" ")
        )}
      >
        <h4>{this.props.title}</h4>
        <div>{slider}</div>
      </div>
    );
  }
}

CheckoutRelatedProducts.propTypes = {
  ...TitleTextProps(true),
  itemsPerSlide: PropTypes.number.isRequired,
  imgSize: PropTypes.shape(MaxSizeProps()),
  ...ItemsAwareProps(
    true,
    null,
    PropTypes.shape({ ...ImageProps(), text: PropTypes.string })
  ),
  className: PropTypes.string,
  wrapIntoCard: PropTypes.bool,
  showSubtitle: PropTypes.bool
};

CheckoutRelatedProducts.defaultProps = {
  className: null,
  wrapIntoCard: true,
  showSubtitle: true,
  itemsPerSlide: 4
};

CheckoutRelatedProducts.mapValueToProps = (value, ownProps) => ({
  ...value.checkout.relatedProducts,
  ...ownProps
});

CheckoutRelatedProducts.mapStateToProps = (state, ownProps) => {
  const mostExpensiveProduct = state.cart.items
    .map(item => item.product)
    .reduce(
      (carry, product) =>
        (carry.newPrice || 0) < product.newPrice ? product : carry,

      {}
    );

  return {
    getState: () => state,
    items: state.checkout.relatedProducts.items.filter(
      product => !state.cart.items.some(item => item.product.id === product.id)
    ),
    mostExpensiveProduct
  };
};

CheckoutRelatedProducts.mapDispatchToProps = dispatch => {
  return {
    dispatch,
    checkoutFetchRelatedProduct: (...args) =>
      checkoutFetchRelatedProduct(...args)(dispatch),
    checkoutFetchRelatedProductSuccess: (...args) =>
      dispatch(checkoutFetchRelatedProductSuccess(...args)),
    checkoutFetchRelatedProductFailure: (...args) =>
      checkoutFetchRelatedProductFailure(...args)(dispatch)
  };
};

export default connectHOCs(CheckoutRelatedProducts, {
  withSite: true,
  withConnect: true,
  withGraphQL: true
});
