import GenericCarousel from "@components-core/GenericCarousel";
import Picture from "@components-core/Picture";
import RouteLink from "@components-core/RouteLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageProps from "@prop-types/ImageProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { LogoListBS } from "@style-variables";
import { mediaBreakpoint, isMobileDevice } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";

const LogoSlider = props => {
  const NavIcon = props => (
    <div>
      <FontAwesomeIcon {...props} size="2x" />
    </div>
  );

  const mdColSpan = Math.floor(12 / props.itemsPerSlide);

  const sliderProps = {
    indicators: false,
    controls: true,
    items: props.children,
    className: props.className,
    itemsPerSlide: props.itemsPerSlide,
    colSpan: { sm: 12, md: mdColSpan },
    prevIcon: <NavIcon icon="chevron-left" />,
    nextIcon: <NavIcon icon="chevron-right" />
  };

  return <GenericCarousel {...sliderProps} />;
};

const LogoHeader = props => {
  const onShowMore = props.showMoreUrl ? null : props.onShowMore;

  const titleCol = props.title ? (
    <Col className="title pl-0" style={{ fontSize: "1.25rem" }}>
      {props.title}
    </Col>
  ) : null;

  const anchor = props.showMoreUrl ? (
    <RouteLink to={props.showMoreUrl} title={props.title} />
  ) : (
    <span
      onClick={onShowMore}
      onKeyDown={onShowMore}
      role="button"
      tabIndex={0}
    >
      {props.showMore}
    </span>
  );

  const showMoreCol = props.showMore ? (
    props.showMoreUrl ? (
      <RouteLink to={props.showMoreUrl} title={props.title}></RouteLink>
    ) : (
      <Col className="show-more text-right pr-0">{anchor}</Col>
    )
  ) : null;

  const header =
    props.title || props.showMore ? (
      <Row className={getComponentClassName(LogoListBS, "header")}>
        {titleCol}
        {showMoreCol}
      </Row>
    ) : null;

  return header;
};

const LogoRow = props => {
  return (
    <Row className={getComponentClassName(LogoListBS, null, props.className)}>
      {props.items.slice(0, props.maxVisibleItems || props.items.length)}
    </Row>
  );
};

const BrandsLogoList = props => {
  const isMobile = isMobileDevice();

  const [maxVisibleItems, setMaxVisibleItems] = useState(
    isMobile ? props.maxVisibleItems.mobile : props.maxVisibleItems.default
  );

  let _items = props.items;

  if (!_items.length) {
    if (props.placeholder) {
      _items = Array.from({ length: 3 }, () => ({
        img: {
          aspect: 0.4641,
          sizes: { any: 237 }
        }
      }));
    }
  }

  const colspan = Math.floor(12 / _items.length);

  const xsColspan = Math.floor(12 / props.xsItemsPerRow);

  const items = _items
    .concat(...Array(_items.length % props.xsItemsPerRow).fill({}))
    .map((item, index) => {
      let imgProps = { title: item.title, alt: item.title };

      if (typeof item.img === "string") {
        imgProps.src = item.img;
      }
      if (typeof item.img === "object") {
        imgProps = { ...imgProps, ...item.img };
      }

      const itemClassName = getComponentClassName(LogoListBS, "item");

      const asLogoLink = (
        <RouteLink to={item.url} title={item.title}>
          <Picture
            key={index}
            {...imgProps}
            placeholder={props.placeholder}
            className={isMobile ? null : itemClassName}
          />
        </RouteLink>
      );

      const asCol = (
        <Col
          xs={xsColspan}
          sm={Math.max(3, colspan)}
          md={Math.max(2, colspan)}
          className={isMobile ? itemClassName : null}
        >
          {asLogoLink}
        </Col>
      );

      return (
        <MediaQuery {...mediaBreakpoint.mobile} key={index}>
          {matches =>
            matches
              ? asCol
              : _items.length > props.itemsPerSlide
                ? asLogoLink
                : asCol
          }
        </MediaQuery>
      );
    });

  return (
    <MediaQuery {...mediaBreakpoint.mobile}>
      {matches => {
        let children;

        // mobile
        if (matches) {
          children = (
            <LogoRow
              className={props.className}
              items={items}
              maxVisibleItems={maxVisibleItems}
            />
          );
        }
        // desktop
        // suitable to slider
        else if (items.length > props.itemsPerSlide && !maxVisibleItems) {
          children = (
            <LogoSlider
              className={getComponentClassName(
                LogoListBS,
                null,
                props.className
              )}
              itemsPerSlide={props.itemsPerSlide}
            >
              {items}
            </LogoSlider>
          );
        }
        // suitable to row
        else {
          children = (
            <LogoRow
              className={props.className}
              items={items}
              maxVisibleItems={maxVisibleItems}
            />
          );
        }

        return (
          <Container
            fluid
            className={getComponentClassName(LogoListBS, "wrapper", "mt-2")}
          >
            <LogoHeader
              title={props.title}
              showMore={
                maxVisibleItems && maxVisibleItems < items.length
                  ? props.showMore
                  : null
              }
              showMoreUrl={props.showMoreUrl}
              onShowMore={e => {
                setMaxVisibleItems(0);
              }}
            />
            {children}
          </Container>
        );
      }}
    </MediaQuery>
  );
};

export default BrandsLogoList;

BrandsLogoList.propTypes = {
  ...ItemsAwareProps(false, null, PropTypes.shape(ImageProps())),
  i18n: PropTypes.object,
  placeholder: PropTypes.bool,
  itemsPerSlide: PropTypes.number,
  xsItemsPerRow: PropTypes.number,
  title: PropTypes.string,
  showMore: PropTypes.string,
  showMoreUrl: PropTypes.string
};

BrandsLogoList.defaultProps = {
  itemsPerSlide: 6,
  xsItemsPerRow: 2,
  maxVisibleItems: {
    mobile: 4,
    default: 6
  },
  title: "Our brands",
  showMore: "Show more"
};
