import ExternalLink from "@components-core/ExternalLink";
import GroupedTable from "@components-core/GroupedTable";
import RouteLink from "@components-core/RouteLink";
import TitledParagraph from "@components-core/TitledParagraph";
import { connectHOCs } from "@components-utils";
import {
  findPathBySelectorType,
  PAGE_KEY_PRODUCT,
  PAGE_KEY_PRODUCT_CATEGORY,
  PRODUCT_SELECTOR_TYPE_BRAND
} from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GraphQLComponent from "@graphql-component";
import gqlProductFileFieldsFragment from "@graphql-query/productFileFieldsFragment.gql";
import gqlProductImageFieldsFragment from "@graphql-query/productImageFieldsFragment.gql";
import gqlProductImageFragment from "@graphql-query/productImageFragment.gql";
import gqlProducts from "@graphql-query/products.gql";
import gqlRelatedProductFragment from "@graphql-query/relatedProductFragment.gql";
import gqlSEOScoreFragment from "@graphql-query/seoScoreFragment.gql";
import { pushProductBreadcrumb } from "@redux-actions/breadcrumbs";
import {
  postProductRating,
  postProductRatingFailure,
  postProductRatingSuccess
} from "@redux-actions/product";
import {
  ProductAvailabilityTransformer,
  ProductButtonsTransformer,
  ProductFavoriteTransformer
} from "@transformers/Product";
import { getCloudinaryUrl, getTimeVersion } from "@utils/cloudinary";
import { debug } from "@utils/debug";
import { allowProductReview, setCanonicalUrl } from "@utils/functions";
import { escapeReact } from "@utils/react";
import { openGraphObjects, productStructuredData } from "@utils/richdata";
import { stringToSlug } from "@utils/strings";
import React from "react";
import { Container } from "react-bootstrap";
import ProductBrandContainer from "./BrandContainer";
import ProductCollapsibleFAQ from "./CollapsibleFAQ";
import ProductPackage from "./Package";
import Product from "./Product";
import ProductReviewContainer from "./ReviewContainer";

const hasProductReview = allowProductReview();

const getReview = (product, site, i18n, onWriteReview) => {
  return {
    readCaption: site.siteConfig.i18n.BTN_READ_REVIEW,
    writeCaption: site.siteConfig.i18n.BTN_WRITE_REVIEW,
    events: {
      onNewReview: onWriteReview,
      onCancelNewReview: null,
      onSubmitNewReview: payload =>
        site
          .dispatch(
            postProductRating(
              {
                productId: product.id,
                userName: payload.name,
                userEmail: payload.email,
                userRating: payload.score,
                userReview: payload.review,
                userSubject: payload.subject
              },
              site.siteConfig
            )
          )
          .then(result => {
            site.dispatch(postProductRatingSuccess(result));

            return result;
          })
          .catch(error => {
            site.dispatch(
              postProductRatingFailure(
                error,
                site.siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context
                  .PRODUCT_REVIEW,
                false
              )
            );
            throw error;
          })
    },
    setup: {
      activeTabIndex: 4, // Omdöme tab index
      writeForm: {
        title: i18n.setup.form.title.replace("%product-name%", product.title),
        fields: {
          score: {
            title: i18n.setup.form.score.title,
            hint: i18n.setup.form.score.hint
          },
          subject: {
            title: i18n.setup.form.subject.title,
            placeholder: i18n.setup.form.subject.placeholder
          },
          name: {
            title: i18n.setup.form.name.title,
            placeholder: i18n.setup.form.name.placeholder
          },
          email: {
            title: i18n.setup.form.email.title,
            placeholder: i18n.setup.form.email.placeholder,
            hint: i18n.setup.form.email.hint
          },
          review: {
            title: i18n.setup.form.review.title,
            placeholder: i18n.setup.form.review.placeholder
          }
        },
        buttons: {
          submit: {
            title: i18n.setup.form.buttons.submit,
            variant: "success"
          },
          cancel: {
            title: i18n.setup.form.buttons.cancel,
            variant: "secondary"
          }
        }
      }
    },
    items: product.review
  };
};

const getTopFeaturesTab = ({ product, i18n }) => ({
  title: i18n.tabs_caption.topFeatures,
  disabled: !product.ups,
  content: props => {
    const result = {
      as: props => <div>{props.children}</div>,
      props: {
        children: props.ups
      }
    };

    if (!props.topFeatures.length) {
      result.props.text = (
        <FontAwesomeIcon
          icon="surprise"
          className="text-light text-center w-100"
          size="10x"
        />
      );
    }

    return result;
  },
  active: true,
  className: "product-top-features",
  id: "product-top-features",
  onClick: e => {}
});

const getInfoTab = ({ product, i18n }) => ({
  title: i18n.tabs_caption.info,
  disabled: !(product.info.title || product.info.text),
  content: props => {
    const result = {
      as: TitledParagraph,
      props: { ...props.info, title: null }
    };

    if (!result.props.text) {
      result.props.text = (
        <FontAwesomeIcon
          icon="surprise"
          className="text-light text-center w-100"
          size="10x"
        />
      );
    }

    return result;
  },
  active: false,
  className: "product-information",
  id: "product-info",
  onClick: e => {}
});

const getIncludedProductsTab = ({ product, i18n, site, config }) =>
  product.showChildren && product.children.length
    ? {
        title: i18n.tabs_caption.include,
        disabled: !product.children.length,
        content: props => {
          const children = props.children.map(child => ({
            ...child,
            buttons: ProductButtonsTransformer(child, config)
          }));

          return {
            as: props => (
              <ProductPackage
                items={children}
                supportsTagDiscount={site.supportsTagDiscount}
              />
            ),
            props: {}
          };
        },
        active: false,
        className: "product-included-products",
        id: "product-child",
        onClick: e => {}
      }
    : null;

const getSpecsTab = ({ product, i18n, site }) => ({
  title: i18n.tabs_caption.specs.title,
  disabled: !product.specs.items.length,
  content: props => {
    const specItems = props.specs.items.map(item => ({
      ...item,
      items: item.items.map(spec => {
        let value = spec.value.join(" | ");

        if (spec.type === "attribute_trademark_filter") {
          const url = findPathBySelectorType(
            site.siteConfig.pathfinder,
            PRODUCT_SELECTOR_TYPE_BRAND,
            stringToSlug(value).toLowerCase()
          );

          value = (
            <RouteLink url={url} title={value}>
              {value}
            </RouteLink>
          );
        }

        return {
          ...spec,
          value
        };
      })
    }));

    return {
      as: GroupedTable,
      props: {
        ...props.specs,
        items: specItems,
        stripped: false,
        limit: {
          value: props.specs.limit,
          title: i18n.tabs_caption.specs.show_more,
          asColumn: true
        }
      }
    };
  },
  className: "product-specification",
  id: "product-specification",
  onClick: e => {}
});

const getBrandTab = ({ product, i18n, site }) =>
  product.brand.text
    ? {
        title: i18n.tabs_caption.brand,
        disabled: !(
          product.brand.title ||
          product.brand.text ||
          product.brand.imgSrc
        ),
        content: props => {
          return {
            as: ProductBrandContainer,
            props: {
              ...props.brand,
              layout: ProductBrandContainer.LAYOUT_VERTICAL,
              url: findPathBySelectorType(
                site.siteConfig.pathfinder,
                PRODUCT_SELECTOR_TYPE_BRAND,
                product.brand.searchKey
              )
            }
          };
        },
        className: "product-brand",
        id: "product-brand",
        onClick: e => {}
      }
    : null;

const getFAQTab = ({ product, i18n, site }) => ({
  title: i18n.tabs_caption.faq,
  disabled: !product.faq.items.length,
  content: props => ({
    as: ProductCollapsibleFAQ,
    props: {
      ...props.faq,
      items: props.faq.items.map(item => ({
        ...item,
        text: escapeReact(item.text, site.siteConfig.pathfinder)
      }))
    }
  }),
  className: "product-faq",
  id: "product-faq",
  onClick: e => {}
});

const getDocumentsTab = ({ product, i18n, site }) => ({
  title: i18n.tabs_caption.document,
  disabled: !product.files.length,
  content: props => ({
    as: props => (
      <Container>
        {props.items.map((file, i) => {
          let iconName = "download";
          // TODO: in case we want to detect invalid download links we can follow the example at src/components/core/CardItem.js
          if (/pdf/i.test(file.extension)) {
            iconName = "file-pdf";
          } else if (/doc|docx/i.test(file.extension)) {
            iconName = "file-word";
          } else {
            iconName = "file-alt";
          }

          return (
            <ExternalLink
              icon={{ name: iconName, className: "mr-2", style: {} }}
              download={file.title}
              title={file.title}
              href={getCloudinaryUrl({
                src: file.src,
                cloudinary: file.cloudinary,
                version: file.version,
                extension: file.extension,
                seoSuffix: file.title,
                raw: true
              })}
              key={i}
            >
              {file.title}
            </ExternalLink>
          );
        })}
      </Container>
    ),
    props: { items: product.files }
  }),
  className: "product-docs",
  id: "product-docs",
  onClick: e => {}
});

const getReviewTab = ({ product, i18n }) =>
  hasProductReview
    ? {
        title: i18n.tabs_caption.review,
        disabled: !product.review.items.length,
        content: props => {
          return {
            as: ProductReviewContainer,
            props: { items: props.review.items }
          };
        },
        className: "product-review",
        id: "product-review",
        onClick: e => {}
      }
    : null;

const getInspirationTab = ({ product, i18n }) => ({
  title: i18n.tabs_caption.inspiration,
  disabled: !product.inspiration,
  content: props => {
    console.log({ props, product });
    const result = {
      as: TitledParagraph,
      props: {
        text: product.inspiration,
        title: null
      }
    };

    if (false) {
      result.props.text = (
        <FontAwesomeIcon
          icon="surprise"
          className="text-light text-center w-100"
          size="10x"
        />
      );
    }

    return result;
  },
  active: false,
  className: "product-inspiration",
  id: "product-inspiration",
  onClick: e => {}
});

const getTabs = props => ({
  onChange: e => {},
  items: [
    getTopFeaturesTab(props),
    getInfoTab(props),
    getIncludedProductsTab(props),
    getSpecsTab(props),
    getBrandTab(props),
    props.site.showFaqTab ? getFAQTab(props) : null,
    getDocumentsTab(props),
    getReviewTab(props),
    getInspirationTab(props)
  ].filter(tab => tab && (!tab.disabled || props.site.showDisabledTabs))
});

const getOpenGraph = (product, site) => ({
  title: product.helmet.title,
  description: product.helmet.meta.name.description,
  image: [
    {
      src: product.img.src,
      cloudinary: product.img.cloudinary
    }
  ]
    .concat(
      ...product.images.map(image => ({
        src: image.img.src,
        cloudinary: image.img.cloudinary
      }))
    )
    .filter(
      (value, index, self) =>
        self.findIndex(image => image.src === value.src) === index
    )
    .map(image =>
      getCloudinaryUrl({
        ...image,
        version: product.img.version
          ? getTimeVersion("v", product.img.version)
          : null,
        seoSuffix: product.title
          ? stringToSlug(product.title.toLowerCase())
          : product.title
      })
    ),
  site_name: site.title
});

const placeholderItems = [
  {
    categorySearchKey: [],
    title: "0",
    subtitle: "0",
    img: {},
    images: [{ img: {} }],
    related: [],
    info: { title: "0" },
    specs: { items: [] },
    brand: {},
    faq: { items: [] },
    review: [],
    seo: {}
  }
];

const productTransformer = (data, site, routerHistory) => {
  // TODO: reduce the function complexity

  const i18n = site.siteConfig.i18n.components.SiteRouteProduct;

  const product = (data.products || placeholderItems).pop();

  if (!product) {
    const path404 = site.siteConfig.pathfinder.get(
      site.httpErrors["404-product"]
    );

    routerHistory.push(path404, {
      key: site.searchKey
    });

    return null;
  }

  product.files = product.files || [];

  const onWriteReview = payload => debug(payload, "debug");

  const config = {
    ...site.siteConfig,
    dispatch: site.dispatch,
    checkoutLocked: site.checkoutLocked
  };

  if (product.energyLabelImg && product.energyLabelImg.src) {
    product.images = product.images.map(image => {
      image.isEnergyLabel = product.energyLabelImg.src === image.img.src;

      return image;
    });
  }

  product.availability = ProductAvailabilityTransformer(product, {
    i18n: site.siteConfig.i18n
  });

  product.buttons = ProductButtonsTransformer(product, config);

  product.related = product.related.map(related => {
    related.buttons = ProductButtonsTransformer(related, config);
    return related;
  });

  product.paymentMethods = site.paymentMethods;

  product.rating = {
    ...product.rating,
    title: site.siteConfig.i18n.LABEL_CUSTOMER_REVIEW
  };

  product.review = getReview(product, site, i18n, onWriteReview);

  product.tabs = getTabs({
    product,
    i18n,
    site,
    config
  });

  product.helmet = {
    title: product.info.title,
    meta: {
      name: {
        description: product.info.text
        // keywords: product.metaKeywords
        //   ? product.metaKeywords
        //   : product.info.title
      }
    }
  };

  // set the right canonical URL
  setCanonicalUrl(product.helmet, site.siteConfig.pathfinder, site.match, {
    productId: product.searchKey
  });

  // OpenGraph standard meta-tags
  product.helmet.meta.property = openGraphObjects({
    og: getOpenGraph(product, site),
    twitter: { site: "@username", card: "summary" }
  });

  // Structured data for Google search rich result
  const googleRichResult = productStructuredData(
    product,
    product.categorySearchKey.reduce(
      (carry, categoryId) =>
        Object.assign(carry, {
          [categoryId]:
            window.location.origin +
            site.siteConfig.pathfinder.generate(PAGE_KEY_PRODUCT_CATEGORY, {
              categoryId
            })
        }),
      {}
    )
  );

  // for Google Data check out ./application/controllers/ExternalController.php (generateXML, extractData function)
  product.helmet.script = googleRichResult;

  product.i18n = {
    ...site.siteConfig.i18n.components.Product,
    favorite: site.siteConfig.i18n.components.ProductCategoryFavorite
  };

  product.supportsFavorites = site.supportsFavorites;

  product.favorite = ProductFavoriteTransformer(product, {
    ...config,
    favoriteItems: site.favoriteItems
  });

  product.className = site.className;
  product.style = site.style;

  site.pushProductBreadcrumb({
    title: product.info.title,
    href: window.location.pathname
  });

  product.onVariant = (id, searchKey) => {
    console.log({ id, searchKey, site, routerHistory });

    const productId = searchKey || id;

    routerHistory.push(
      site.siteConfig.pathfinder.generate(PAGE_KEY_PRODUCT, { productId })
    );
  };

  return product;
};

const SiteRouteProduct = props => {
  const gqlProps = {
    graphqlClient: props.graphqlClient,
    query: [
      gqlProducts,
      gqlProductImageFragment,
      gqlRelatedProductFragment,
      gqlProductImageFieldsFragment,
      gqlSEOScoreFragment,
      gqlProductFileFieldsFragment
    ],
    variables: {
      siteId: props.siteId,
      includeRelated: false,
      includeChildren: true,
      includeVariants: true
    },
    dataTransformer: data =>
      productTransformer(
        data,
        {
          httpErrors: props.httpErrors,
          match: props.match,
          paymentMethods: props.paymentMethods,
          dispatch: props.dispatch,
          searchKey: props.match.params.productId,
          checkoutLocked: props.checkoutLocked,
          title: props.siteTitle,
          supportsFavorites: props.supportsFavorites,
          supportsTagDiscount: props.supportsTagDiscount,
          favoriteItems: props.favoriteItems,
          siteConfig: props.siteConfig,
          className: props.className,
          style: props.style,
          pushProductBreadcrumb: props.pushProductBreadcrumb,
          showDisabledTabs: props.show_disabled_tabs,
          showFaqTab: props.show_faq_tab
        },
        props.history
      ),
    wraps: Product
  };

  const productId = +props.match.params.productId;

  // if the given route param is an integer, search by search-id
  if (Number.isInteger(productId)) {
    gqlProps.variables.productId = productId;
  }
  // otherwise search by search-key
  else {
    gqlProps.variables.searchKey = props.match.params.productId;
  }

  return <GraphQLComponent {...gqlProps} />;
};

SiteRouteProduct.mapValueToProps = value => ({
  showDisabledTabs: false,
  showFaqTab: false,
  httpErrors: value.httpErrors,
  paymentMethods: value.paymentMethods,
  siteTitle: value.helmet.title || value.title,
  supportsFavorites: value.supportsFavorites
});

// ------------------- REDUX ----------------------

SiteRouteProduct.mapStateToProps = (state, ownProps) => ({
  favoriteItems: state.productFavoriteResult.items,
  checkoutLocked:
    (state.placeOrderResult.isFetching ||
      state.placeOrderResult.status ||
      state.checkout.paymentState.initiated) &&
    !(
      state.checkout.paymentState.success || state.checkout.paymentState.failure
    )
});
SiteRouteProduct.mapDispatchToProps = dispatch => ({
  dispatch,
  pushProductBreadcrumb: (...args) => dispatch(pushProductBreadcrumb(...args))
});

export default connectHOCs(SiteRouteProduct, { withAll: true });
