import { EVENT_MENU_TOGGLED, EVENT_SCROLL_TO_TOP } from "@constants";
import { MenuBarToggleBS } from "@style-variables";
import { createCustomEvent } from "@utils/dom";
import PropTypes from "prop-types";
import React from "react";
import MenuBarButton from "./Button";

/**
 * @description A responsive menubar toggle
 * @export
 * @class MenuBarToggle
 * @extends {MenuBarButton}
 */
export default class MenuBarToggle extends MenuBarButton {
  constructor(props) {
    super(props);

    this.state = { toggled: false };

    this.handleMenuToggler = this.handleMenuToggler.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
  }

  /**
   * @description Get the component CSS classname. Overrides the extended component.
   * @returns {string}
   * @memberof MenuBarToggle
   */
  getClassName() {
    if (this.props.className) {
      return MenuBarToggleBS + " " + this.props.className;
    }
    return MenuBarToggleBS;
  }

  componentDidMount() {
    document.addEventListener(EVENT_MENU_TOGGLED, this.handleMenuToggler);
    document.body.addEventListener("click", this.handleBodyClick);
  }

  componentWillUnmount() {
    document.removeEventListener(EVENT_MENU_TOGGLED, this.handleMenuToggler);
    document.body.removeEventListener("click", this.handleBodyClick);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.toggled !== this.props.toggled) {
      this.setState(this.props.toggled)
    }
  }

  /**
   * @description Handle the body.click event
   * @param {Event} e
   * @memberof MenuBarToggle
   */
  handleBodyClick(e) {
    // The role of this function is to simulate toggle click at on body click, only when dropdown is shown!
    if (this.state.toggled) {
      // ignore the `body click` when it is either the toggle self/child of dropdown menu
      if (!e.target.closest(`#responsive-navbar-nav,.${this.getClassName()}`)) {
        this.handleMenuToggler(e);
      }
    }
  }

  /**
   * @description Show/hide the dropdown menu
   * @param {Event} e
   * @memberof MenuBarToggle
   */
  handleMenuToggler(e) {
    const el = document.getElementById("responsive-navbar-nav");

    if (el) {
      el.classList.toggle("show");
    }

    window.dispatchEvent(createCustomEvent(EVENT_SCROLL_TO_TOP));

    this.setState({ toggled: !this.state.toggled });
  }

  renderButtonIcon() {
    return this.props.toggled ? (
      <button
        type="button"
        className="close"
        aria-label="Close"
        style={{ fontSize: "2rem" }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    ) : (
      super.renderButtonIcon()
    );
  }
}

MenuBarToggle.propTypes = {
  ...MenuBarButton.propTypes,
  position: PropTypes.oneOf(["left", "right"]),
  toggled: PropTypes.bool
};

MenuBarToggle.defaultProps = {
  ...MenuBarButton.defaultProps,
  position: "left",
  toggled: false
};
